import React from 'react'
import { Row, Col, Container } from 'react-bootstrap';
import img1 from '../../../assets/images/1.png'
import img2 from '../../../assets/images/2.png'
import img3 from '../../../assets/images/3.png'
import img4 from '../../../assets/images/4.png'

const datas = [
    { img: img1, title: 'Mella', aTitle: 'mella.ai', href: "https://www.mella.ai/" },
    { img: img2, title: 'FUZZY', aTitle: 'yourfuzzy.com', href: "https://www.yourfuzzy.com/" },
    { img: img3, title: 'Covetrus', aTitle: 'covetrus.com', href: "https://covetrus.com/" },
    { img: img4, title: 'Vetspire', aTitle: 'vetspire.com', href: "https://vetspire.com/" },
]
const Cooperate = () => {


    const setUi = () => {
        let uis = datas.map((item, index) => {
            return <Col md={3} sm={6} >
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }} key={`${index}`}>
                    <div style={{}}>
                        <img src={item.img} alt="" width={"100%"} />
                    </div>

                    <div style={{
                        backgroundColor: "#303030",
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        <h5 style={{ color: "#fff" }}>{item.title}</h5>
                        <h6>
                            <a href={item.href}>{item.aTitle}</a>
                        </h6>

                    </div>
                </div>

            </Col>
        })
        return uis
    }

    return (
        <div className='py-5  ' style={{ backgroundColor: "#404040" }} >
            <Container className='align-items-center py-5'>
                <h3 className='text-center mb-5' style={{ color: "#fff" }}>合作伙伴</h3>
                <div className='px-5 mx-5'>
                    <Row className='align-items-center'>
                        {setUi()}

                    </Row>
                </div>
            </Container>
        </div>

    )
}
export default Cooperate;