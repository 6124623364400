import React from 'react'
import { Row, Col, Container } from 'react-bootstrap';
import img1 from '../../../assets/images/company-img-1.jpg'
import img2 from '../../../assets/images/company-img-2.jpg'
import img3 from '../../../assets/images/company-img-3.jpg'
import img4 from '../../../assets/images/company-img-4.jpg'

const NonagonInfo = () => {

    return (
        <div className='py-5 ' style={{ backgroundColor: "#fff" }}>
            <Container className='py-5 '>
                <div className='px-5 mx-5'>
                    <Row className='align-items-center'>
                        <Col className='text-center align-items-center' sm={6} md={6}>

                            <h2 className='py-2 mb-4' style={{ color: "#008fc1" }}>关于南京九边形科技有限公司</h2>
                            <p style={{ fontSize: 14, color: "#000" }}>
                                南京九边形科技有限公司成立于2019年，位于南京市雨花台软件谷科创城，本公司汇集医学传感器、移动互联网等业界顶级专业人才，业务领域涵盖人工智能、智慧医疗、宠物健康、睡眠监护等，是一家集设计、研发、⽣产、销售、服务于⼀体的高科技公司。九边形科技以"天地之数，始于一，终于九"为文化理念，秉承中华工匠之精髓，胸怀国际化视野，始终站在生物智能医学监护科技的前沿。


                            </p>
                        </Col>
                        <Col className='text-center' sm={5} md={6}>
                            <Row className='align-items-center mb-3'>
                                <Col md="auto"> <img src={img1} alt="" width={200} height={100} /></Col>
                                <Col md="auto"> <img src={img2} alt="" width={200} height={100} /></Col>
                            </Row>
                            <Row>
                                <Col md="auto"> <img src={img3} alt="" width={200} height={100} /></Col>
                                <Col md="auto"> <img src={img4} alt="" width={200} height={100} /></Col>
                            </Row>


                        </Col>
                    </Row>
                </div>







            </Container>
        </div>

    )
}
export default NonagonInfo;