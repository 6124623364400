import React from 'react'
import { Row, Col, Container } from 'react-bootstrap';

const MellaContain = () => {

    return (
        <div className='py-5 ' style={{ backgroundColor: "#F2F2F2" }}>
            <Container className='py-5 px-5'>
                <div className=' mx-5 px-5'>
                    <Row className='align-items-center' >



                        <Col className='align-items-center' sm={6} md={4}>
                            <div style={{ width: '100%', height: 300, position: 'relative' }}>
                                <div className='con1'>

                                </div>
                                <div className='con2'>

                                </div>
                            </div>



                        </Col>
                        <Col md={2} />

                        <Col className='' sm={5} md={6}>
                            <h2 className='py-2 mb-4 text-center' style={{ color: "#008fc1" }}>Mella包含内容</h2>
                            <p className='mb-4'>您是否知道 80% 的家长表示测量体温是看兽医时最糟糕的部分？Mella 提供入职工具来帮助您的员工进行设置。</p>
                            <ul>
                                <li style={{ color: "#008fc1" }}>腋温探头</li>
                                <li style={{ color: "#008fc1" }}>耳温探头</li>
                                <li style={{ color: "#008fc1" }}>肛温探头</li>
                                <li style={{ color: "#008fc1" }}>无线充电器</li>
                                <li style={{ color: "#008fc1" }}>入职工具</li>
                            </ul>


                        </Col>
                    </Row>
                </div>







            </Container>
        </div>

    )
}
export default MellaContain;