import React from 'react'
import { Row, Col, Container } from 'react-bootstrap';
import img1 from '../../../assets/images/company-img-1.jpg'
import img2 from '../../../assets/images/company-img-2.jpg'
import img3 from '../../../assets/images/company-img-3.jpg'
import img4 from '../../../assets/images/company-img-4.jpg'
import img8 from '../../../assets/images/mella/8.png'
import img9 from '../../../assets/images/mella/9.png'
import img10 from '../../../assets/images/mella/10.png'
import img11 from '../../../assets/images/mella/11.png'
import img1_1 from '../../../assets/images/mella/1-1.png'
import img1_2 from '../../../assets/images/mella/1-2.png'
import img1_3 from '../../../assets/images/mella/1-3.png'
import img1_4 from '../../../assets/images/mella/1-4.png'

const AppDown = () => {

    return (
        <div className='py-5 ' style={{ backgroundColor: "#F2F2F2" }}>
            <Container className='py-5 px-5'>
                <div className=' mx-5 px-5'>
                    <Row className='align-items-center'>

                        <Col className='' sm={5} md={4}>
                            <img src={img11} alt="" width={"100%"} />
                            <p className='text-center my-2'>提供精准的预测</p>
                            <Row>
                                <Col md={3} className='px-1'><img src={img1_1} alt="" width={"100%"} /></Col>
                                <Col md={3} className='px-1'><img src={img1_2} alt="" width={"100%"} /></Col>
                                <Col md={3} className='px-1'><img src={img1_3} alt="" width={"100%"} /></Col>
                                <Col md={3} className='px-1'><img src={img1_4} alt="" width={"100%"} /></Col>
                            </Row>



                        </Col>
                        <Col md={2} />
                        <Col className='align-items-center' sm={6} md={6}>

                            <h2 className='py-2 mb-4' style={{ color: "#008fc1" }}>Mella应用程序</h2>
                            <p>Mella 不仅可以测量宠物的体温，还可以在您的智能设备或桌面上显示结果。Mella 实时共享多个设备上的温度数据，包括您的实践管理软件。</p>
                            <p className='my-4' style={{ color: "#008fc1" }}>可在移动设备和桌面设备上使用</p>
                        </Col>
                    </Row>
                </div>







            </Container>
        </div>

    )
}
export default AppDown;