import React from 'react'
import { Row, Col, Container } from 'react-bootstrap';


const AboutUs = () => {



    return (
        <div className='py-5  ' style={{ backgroundColor: '#fff' }}>
            <Container className='align-items-center my-5'>
                <Row className='px-5'>
                    <Col className='text-center' md={12} data-wow-delay="1000">
                        <h3 className='text-center mb-5' >关于我们</h3>

                    </Col>
                    <Col md={2} />
                    <Col md={8} className='text-center'>
                        <p>我们因人工智能物联网时代而诞生。</p>
                        <p>公司拥有一支敢于担当，勇于创新，乐观自信，精诚团结、志向远大的团队。</p>
                        <p className='px-5'>我们以市场为导向，以客户为中心，满足客户需求，为客户提供超越客户期望的高品质服务，以工匠精神诠释中国制造，为实现民族复兴中国梦而努力。</p>

                    </Col>
                    <Col md={2} />
                    <div style={{ marginTop: 40 }}></div>
                    <Col sm={6} md={4} data-wow-delay="2000"  >
                        <Row>
                            <Col md="auto">
                                <i className="fa fa-heart pull-left media-object"></i>

                            </Col>
                            <Col>
                                <div className="media-body">
                                    <h5 className="text-center mb-3" style={{ color: "#008fc1" }}>企业理念</h5>
                                    <p className="text-center"> 以市场为导向，以客户为中心，提供最高品质的服务，以工匠精神诠释中国制造，引领行业进步，共享美好生活.</p>
                                </div>

                            </Col>
                        </Row>



                    </Col>
                    <Col sm={6} md={4} data-wow-delay="2000"  >
                        <div class="media">
                            <Col md="auto">
                                <i className="fa fa-heart pull-left media-object"></i>

                            </Col>
                            <div class="media-body">
                                <h5 className="text-center mb-3" style={{ color: "#008fc1" }}>核心价值</h5>
                                <p className="text-center">诚信:互信担当,言行信果,诚实反馈；</p>
                                <p className="text-center">专注:自我提升,持续专注,追求卓越；</p>
                                <p className="text-center">关爱:关心互助,心系客户,荣辱与共；</p>
                                <p className="text-center">热情:热爱工作,求知创新,勇于挑战；</p>
                            </div>
                        </div>
                    </Col>
                    <Col sm={6} md={4} data-wow-delay="2000"  >
                        <div class="media">
                            <Col md="auto">
                                <i className="fa fa-heart pull-left media-object"></i>

                            </Col>
                            <div class="media-body">
                                <h5 className="text-center mb-3" style={{ color: "#008fc1" }}>联系我们</h5>
                                <p className="text-center"> 企业邮箱:nonagontech@126.com<br />公司地址:江苏省南京市雨花台区凤展路32号A1幢807室</p>
                            </div>
                        </div>
                    </Col>
                </Row>


            </Container>
        </div>

    )
}
export default AboutUs;