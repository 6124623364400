import React, { useState } from 'react'
import Footer from '../home/Footer';
import MellaCarousel from './MellaCarousel';
import './index.less'
import MellaHeard from './Header';
import HowWork from './HowWork';
import AppDown from './App';
import PWM from './Pwm';
import MellaContain from './Contains';
const Mella = () => {
    const [count, setCount] = useState(0);

    return (
        <div id="mella">
            <MellaHeard />
            <MellaCarousel />
            <div id="howWodk"></div>
            <HowWork />
            <div id="appDown"></div>
            <AppDown />
            <div id="pwm"></div>
            <PWM />
            <div id="MellaContain"></div>
            <MellaContain />

            <Footer />
        </div>
    )
}
export default Mella;