import React from 'react'
import { useHistory } from 'react-router-dom';
import { Nav, NavDropdown, Container, Navbar, Row, Col } from 'react-bootstrap'

const MellaHeard = () => {
    let history = useHistory();
    const scrollToAnchor = (name) => {
        if (name) {
            let el = document.getElementById(name)
            console.log(el);
            el.scrollIntoView()
        }
    }

    return (

        <Navbar bg="light" data-bs-theme="light" expand="md" fixed='top'>
            <Container>
                <Navbar.Brand onClick={() => {
                    history.push("/")
                }}>
                    {/* 居中对齐 */}
                    <Row className='align-items-center'>
                        <Col>
                            <div id="Logo"></div>

                        </Col>
                        <Col>南京九边形科技有限公司</Col>
                    </Row>
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <div>
                    <Navbar.Collapse id="responsive-navbar-nav" >
                        <Nav className="ml-auto">
                            <Nav.Link onClick={() => { history.push("/") }}>主页</Nav.Link>
                            <NavDropdown title="产品" id="basic-nav-dropdown">
                                <NavDropdown.Item >Mella温度计</NavDropdown.Item>
                                {/* <NavDropdown.Item href="#action/3.2">RFID</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.3">睡眠检测项目</NavDropdown.Item> */}
                            </NavDropdown>
                            <Nav.Link onClick={() => { scrollToAnchor('howWodk') }}>如何工作</Nav.Link>
                            <Nav.Link onClick={() => { scrollToAnchor('appDown') }}>App</Nav.Link>
                            <Nav.Link onClick={() => { scrollToAnchor('pwm') }}>PIMS集成</Nav.Link>
                            <Nav.Link onClick={() => { scrollToAnchor('MellaContain') }}>包含内容</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </div>



            </Container>
        </Navbar>


    )
}
export default MellaHeard;