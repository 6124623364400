import React from 'react'
import { Row, Col, Container } from 'react-bootstrap';
import img1 from '../../../assets/images/company-img-1.jpg'
import img2 from '../../../assets/images/company-img-2.jpg'
import img3 from '../../../assets/images/company-img-3.jpg'
import img4 from '../../../assets/images/company-img-4.jpg'
import img8 from '../../../assets/images/mella/8.png'
import img9 from '../../../assets/images/mella/9.png'
import img10 from '../../../assets/images/mella/10.png'
import vido from "./../../../assets/images/mella/vidio.mp4"
import ReactPlayer from 'react-player'//引入


const HowWork = () => {

    return (
        <div className='py-5 ' style={{ backgroundColor: "#fff" }}>
            <Container className='py-5 px-5'>
                <div className=' mx-5 px-5'>
                    <Row className='align-items-center'>
                        <Col className='align-items-center' sm={6} md={6}>

                            <h2 className='py-2 mb-4' style={{ color: "#008fc1" }}>Mella如何工作?</h2>
                            <Row className='mb-4'>
                                <Col md={2}>
                                    <img src={img8} alt="" width={'100%'} />
                                </Col>
                                <Col md={6}>
                                    <p style={{ color: "#008fc1" }}>措施</p>
                                    <p>快速、非侵入性放置在腋下 + 与任何连接设备的蓝牙连接</p>
                                </Col>
                            </Row>
                            <Row className='mb-4'>
                                <Col md={2}>
                                    <img src={img9} alt="" width={'100%'} />
                                </Col>
                                <Col md={5}>
                                    <p style={{ color: "#008fc1" }}>跟踪结果</p>
                                    <p>数据跟踪、宠物档案和工作流工具无缝集成</p>
                                </Col>
                            </Row>
                            <Row className='mb-4'>
                                <Col md={2}>
                                    <img src={img10} alt="" width={'100%'} />
                                </Col>
                                <Col md={5}>
                                    <p style={{ color: "#008fc1" }}>分享结果</p>
                                    <p>兽医将结果直接上传到患者管理系统</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col className='text-center' sm={5} md={6}>
                            <ReactPlayer
                                className='react-player'
                                url={vido}//地址
                                width='100%'//宽度
                                height='100%'//高度
                                playing={false}//是否自动播放
                                controls//控制
                            />


                        </Col>
                    </Row>
                </div>







            </Container>
        </div>

    )
}
export default HowWork;