import React from 'react'
import { Row, Col, Container, Form, } from 'react-bootstrap';


const Add = () => {



    return (
        <div className='py-5 text-center ' style={{ color: "#fff" }}>
            <Container className='align-items-center my-5'>
                <Row className='px-5'>
                    <Col className='text-center' md={12} data-wow-delay="1000">
                        <h3 className='text-center mb-5' >人才招聘</h3>

                    </Col>
                    <Col md={2} />
                    <Col md={8} className='text-center'>
                        <p>风云变幻，初心不改。中流击水，浪遏飞舟。</p>
                        <p>如果你有志于探索未知、创造未来。用自己的聪明才智，为人类社会的进步做贡献。</p>
                        <p>欢迎加入我们，共同成长进步。</p>
                    </Col>
                    <Col md={2} />
                    <div style={{ marginTop: 40 }}></div>
                    <Form>
                        <Row>
                            <Col md={4} className='py-3'>
                                <input type="text" placeholder="姓名" class="form-control" />
                            </Col>
                            <Col md={4} className='py-3'>
                                <input type="text" placeholder="年龄" class="form-control" />
                            </Col>
                            <Col md={4} className='py-3'>
                                <input type="text" placeholder="性别" class="form-control" />
                            </Col>
                            <Col md={4} className='py-3'>
                                <input type="text" placeholder="邮箱" class="form-control" />
                            </Col>
                            <Col md={4} className='py-3'>
                                <input type="text" placeholder="手机号码" class="form-control" />
                            </Col>
                            <Col md={4} className='py-3'>
                                <input type="text" placeholder="职位" class="form-control" />
                            </Col>
                            <Col md={12} className='py-3 mb-5'>
                                <textarea type="text" placeholder="自我介绍" class="form-control" />
                            </Col>
                            <Col md={3} />
                            <Col md={6}>
                                <input type="submit" value="申请" style={{ backgroundColor: '#008fc1', color: '#fff', width: '80%', border: 0, padding: '10px 0' }} />

                            </Col>
                            <Col md={3} />
                        </Row>
                    </Form>
                </Row>


            </Container>
        </div>

    )
}
export default Add;