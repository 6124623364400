import React from 'react'
import { Row, Col, Container } from 'react-bootstrap';

import img1 from '../../../assets/images/mella/2.1.png'
import img2 from '../../../assets/images/mella/2.5.png'
import img3 from '../../../assets/images/mella/3.1.png'
import img4 from '../../../assets/images/mella/3.6.png'

const PWM = () => {

    return (
        <div className='py-5 ' style={{ backgroundColor: "#fff" }}>
            <Container className='py-5 px-5'>
                <div className=' mx-5 px-5'>
                    <Row className='align-items-center'>

                        <Col className='' sm={5} md={4}>
                            <h2 className='py-2 mb-4 text-center' style={{ color: "#008fc1" }}>Mella 与者管理系统集成？</h2>
                            <p>对于非集成诊所，Mella 是写回 PiMS 的通道。虽然这意味着每只宠物在 Mella 桌面应用程序中不会有单独的配置文件，但温度读数可以发送回任何 PiMS。仍然具有创新性和节省时间。</p>



                        </Col>
                        <Col md={2} />
                        <Col className='align-items-center' sm={6} md={6}>

                            <p className='py-2 mb-3' style={{ color: "#008fc1" }}>  Mella 完全集成于：</p>
                            <img src={img1} alt="" width={"80%"} />
                            <img className='mt-3 mb-3' src={img2} alt="" width={"60%"} />
                            <p className='py-2 mb-3' style={{ color: "#008fc1" }}>  Mella 完全集成于：</p>
                            <img src={img3} alt="" width={"80%"} />
                            <img className='mt-3 mb-3' src={img4} alt="" width={"60%"} />
                        </Col>
                    </Row>
                </div>







            </Container>
        </div>

    )
}
export default PWM;