import React from 'react';
import { Carousel, Button } from 'react-bootstrap';
import Img from './../../../assets/images/slider/3.jpg';
import mella from './../../../assets/images/slider/mella.jpg';
import { useHistory } from 'react-router-dom';

function ControlledCarousel() {
    let history = useHistory();


    return (
        <Carousel >
            <Carousel.Item interval={4000}>
                <img src={mella} alt="" width={"100%"} />
                <Carousel.Caption>
                    <h3>Mella宠物温度计</h3>
                    <p>放心测量宠物的体温，并在智能设备上查看结果。数据会自动记录并准备与您的兽医共享。</p>
                    <Button style={{ backgroundColor: '#008fc1' }} onClick={() => {
                        history.push("/mella")
                    }}>了解更多</Button>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={5000} draggable="false">
                <img src={Img} alt="" width={"100%"} />
                <Carousel.Caption>
                    <h3>干预式睡眠</h3>
                    <p>通过生物反馈校准进行个性化放松</p>
                    <Button style={{ backgroundColor: '#008fc1' }}>了解更多</Button>
                </Carousel.Caption>
            </Carousel.Item>

        </Carousel>
    );
}

export default ControlledCarousel;