import React from 'react';
import { Carousel, Tabs, Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import Img1 from './../../../assets/images/mella/1.png';
import Img2 from './../../../assets/images/mella/2.png';
import Img3 from './../../../assets/images/mella/3.png';
import Img4 from './../../../assets/images/mella/4.png';
import Img5 from './../../../assets/images/mella/5.png';
import Img6 from './../../../assets/images/mella/6.png';

const list = [Img2, Img3, Img4, Img5, Img6, Img1,]

function MellaCarousel() {

    const setUi = () => {
        var netlist = list.map((item, index) => {
            return <Carousel.Item interval={4000} key={`${index}`}>
                <img src={item} alt="" width={"100%"} />

            </Carousel.Item>

        })
        return netlist;
    }


    return (
        <div className='py-5' variant="dark" style={{ backgroundColor: '#F2F2F2' }}>
            <Container className='pt-5'>
                <Row className='pt-5'>
                    <Col md={3}>
                        <Carousel >
                            {setUi()}
                        </Carousel>
                    </Col>
                    <Col md={4} />
                    <Col md={5} className='align-items-center'>
                        <h2 className='text-center mb-5'>Mella Pro温度计</h2>


                        <Tabs

                            id="justify-tab-example"
                            className="mb-3"
                            justify
                        >
                            <Tab eventKey="home" title="Mella是什么?" tabClassName="test">
                                通过将 Mella Pro 放置在前腿或后腿下方，Mella 率先测量出准确的腋窝温度。该设备测量体温并在大约 10-15 秒内显示结果。数据自动记录并可发送至实践管理系统
                            </Tab>
                            <Tab eventKey="profile" title="Mella特征">
                                <div className='align-items-center tzFa'>
                                    <ul className='tz '>
                                        <li>准确的</li>
                                        <li>高效的</li>
                                        <li>非入侵性</li>
                                        <li>方便使用的</li>
                                        <li>PiMS集成</li>
                                        <li>临床测试</li>
                                    </ul>
                                </div>

                            </Tab>
                            <Tab eventKey="profile1" title="Mella包含什么?">
                                <div className='align-items-center tzFa'>
                                    <ul className='tz1 '>
                                        <li>Mella Health 应用程序（与移动设备或桌面设备配对）</li>
                                        <li>耳温探头</li>
                                        <li>腋温探头</li>
                                        <li>肛温探头</li>
                                        <li>蓝牙无线充电底座,可连接到 Mella Desktop 应用程序</li>

                                    </ul>
                                </div>

                            </Tab>


                        </Tabs>
                    </Col>
                </Row>

            </Container>

        </div>

    );
}

export default MellaCarousel;