import React from "react";
import "./index.less";
import HomeHeard from "./Header";
import ControlledCarousel from "./Carousel";
import NonagonInfo from "./NonagonInfo";
import Cooperate from "./Cooperate";
import AboutUs from "./AboutUs";
import Footer from "./Footer";
import Add from "./Add";

const Home = () => {
  return (
    <div id="home">
      <HomeHeard />
      <div id="top" />
      <ControlledCarousel />
      <NonagonInfo />
      <div id='Cooperate' />
      <Cooperate />
      <div id='AboutUs' />
      <AboutUs />
      <div id='Add' />
      <Add />
      <Footer />
    </div>

  );
};

export default Home;
