import React, { Component } from 'react'
import { Switch, Route, HashRouter } from 'react-router-dom';
import Home from './pages/home';
import 'bootstrap/dist/css/bootstrap.min.css'
import Mella from './pages/Mella';

export default class App extends Component {

  render() {
    return (
      <HashRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/mella" component={Mella} />
        </Switch>
      </HashRouter>
    )
  }

}
