import React from 'react'
import { Row, Col, Container } from 'react-bootstrap';
import { Button } from 'antd'

const Footer = () => {
    const [aColor, setAColor] = React.useState("#fff")
    const [aColor1, setAColor1] = React.useState("#fff")
    return (
        <div style={{ backgroundColor: "#404040" }} s>
            <Container className='pb-5 pt-4' >
                <div className='px-5 mx-5 alight-items-center' >
                    <Row className='align-items-center mb-3' >
                        <Col className='text-center' md={3} style={{ color: "#fff" }}>邮箱:nonagontech@126.com </Col>
                        <Col className='text-center' md={7} style={{ color: "#fff" }}>地址:南京市江宁区双龙大道1704号融汇时代中心701室（江宁开发区）  </Col>
                        <Col className='text-center' md={2} style={{ color: "#fff" }}> 邮编:211106 </Col>

                    </Row>
                    <Row className='align-items-center'>
                        <Col md={5} style={{ color: "#fff" }}>Copyright@2019~2023 南京九边形科技有限公司 版权所有</Col>
                        <Col md={3} style={{ color: "#fff" }}>
                            <a onMouseOver={() => {
                                setAColor("#ff6a00")
                            }}
                                onMouseOut={() => {
                                    setAColor("#fff")
                                }}
                                style={{ color: aColor, textDecoration: 'none' }} href="https://beian.miit.gov.cn/">苏ICP备2020062879号</a>
                        </Col>
                        <Col md={3} style={{ color: "#fff" }}>
                            <a onMouseOver={() => {
                                setAColor1("#ff6a00")
                            }}
                                onMouseOut={() => {
                                    setAColor1("#fff")
                                }}
                                style={{ color: aColor1, textDecoration: 'none' }} href="https://9bxkj.com:8091/">隐私协议</a>
                        </Col>

                    </Row>
                </div>







            </Container>
        </div>



    )
}
export default Footer;